import $ from "jquery";

$(document).ready(function(){
    // Маска ввода телефона
    $('input[data-mask]').each(function () {
        $(this).mask($(this).attr('data-mask'));
    });
    
    // Курсор в поле воода в нужном месте
    $.fn.setCursorPosition = function (pos) {
        if ($(this).get(0).setSelectionRange) {
            $(this).get(0).setSelectionRange(pos, pos);
        } else if ($(this).get(0).createTextRange) {
            var range = $(this).get(0).createTextRange();
            range.collapse(true);
            range.moveEnd('character', pos);
            range.moveStart('character', pos);
            range.select();
        }
    };
    
    $('input[data-mask]').click(function () {
        if ($(this).val() == '+38 0__ ___ __ __') {
            $(this).setCursorPosition(5);
        }
    });
    
    $('.main_menu-close').click(function() {
        $('.main_menu').fadeOut();
        $('html').css('overflow-y', 'auto')
    })

    // Всплывающее меню
    $('.nav-button').click(function () {
        if (!$('.nav-control').hasClass('op')) {
            $('.nav-control').addClass('op');
            $('.main_menu').addClass('active')
        } else {
            $('.nav-control').removeClass('op');
            $('.main_menu').removeClass('active')
        }
    });
    
    $('.faq_item-head').click(function () {
        var faqIt = $(this).closest('.faq_item');
        faqIt.toggleClass('open');
        faqIt.find('.faq_body').slideToggle(400);
    });
    
    // Сворачивание меню
    $('[data-event="open_menu"]').click(function() {
        $('.side_menu').toggleClass('collapsed');
        $(this).toggleClass('act_coll');
    })
    
    if (window.innerWidth < 1224) {
        $('[data-event="open_menu_mob"]').click(function() {
            $('.side_menu').addClass('open');
        });
        
        $(document).click(function(e) {
            if ($(e.target).closest(".side_menu").length || $(e.target).closest('[data-event="open_menu_mob"]').length) {
                return
            }
            $('.side_menu').removeClass('open');
        });
    }

    // Плавный скрол к блоку
    $(".scroll_but").click(function (e) {
        e.preventDefault();
        var id = $(this).attr('href'),
            top = $(id).offset().top;
        $('body,html').animate({
            scrollTop: top-70
        }, 600);
    });

    // Зависающая шапка при скролле
    if (window.innerWidth > 768) {
        if ($(window).scrollTop() > 0) {
            $('.header').addClass("scroll");
        }

        $(window).scroll(function (e) {
            if ($(this).scrollTop() > 0) {
                $('.header').addClass("scroll");
            } else if ($(this).scrollTop() <= 0) {
                $('.header').removeClass("scroll");
            };
        });
    }
    
});